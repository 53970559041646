$lightGray:hsl(219, 15%, 23%);
$fontColor:hsl(0, 0%, 100%);
$accentColor:hsl(180, 100%, 50%);
$pastelAccent: #9DC7C8;


.App-header {
    position: relative;

    writing-mode: vertical-lr;
    text-orientation: upright;

    background-color: transparent;
    height: 100%;
    width: 5%;
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    float: right;
    z-index: 5;
    font-family: 'Bebas Neue', cursive;
    font-weight: bolder;
    font-size: calc(12px + 2vmin);
    color: 	$fontColor;

    .App-header-name {
      position: relative;

      margin: auto;

      h5 {
        transition-duration: .8s;
        margin: 0;
      }
      h5:hover {
        cursor:default;
        color: $pastelAccent;
      }
    }

    .Social-icons-cont {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 20%;
      color: $fontColor;
      

      .Social-icon-link {
        width: 100%;
        height: 33%;
        color: $fontColor;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10%;
      }
      .Social-icon-img {
        max-width: 100%;
        max-height: 100%;
        transition: .5s;
        box-sizing: content-box;
      }
      .Social-icon-link:hover {
        color: $pastelAccent;
        
        .Social-icon-img {
          padding-left: 40%;
          transition: .5s;

        }
      }
    }

}