$thick : 3px;
$pad : 0.7em;
$extra : calc(#{$pad} * 1.1);
$color : #f26522;


.scroll-container {
    position: fixed;
    left: 0;
    right: 0;
    will-change: transform;
    
  
    .ghost {
      width: 100vw;
    }
}

  
.panels-container {
  position: relative;
  width: max-content;
  display: flex;
  align-items: center;
  background-color: black;

  .panels {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: flex-start;
    text-align: left;
    font-family: 'Bebas Neue', cursive;
    height: 100%;
    width: max-content;
    margin: 0;

    .panel-title {
      position: relative;
      max-width: 100vw;
      min-width: 300px;
      height: 100%;

      .title-section {
        height: 60%;
        padding-left: 3%;
        padding-top: 6%;
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        align-items: flex-start;

        h1 {
          font-size: calc(30px + 8vmin);
          margin-bottom: 2%;
          z-index: 10;
        }
  
        h2 {
          font-size: calc(14px + 2.5vmin);
          margin-bottom: 5%;
          letter-spacing: .03em;
          z-index: 10;
        }

        p {
            max-width: 60%;
            font-size: max(16px, 2.4vmin);
            letter-spacing: .01em;
            z-index: 10;
        }
      }
  
      .blue-download {
        
        width: 50%;
        height: 50%;

        align-self: flex-start;
        display: flex;

        justify-content: flex-start;
        align-items: center;
        z-index: 2;
  
        a {
          padding: $pad $extra;
          text-align: center;
          text-decoration: none;
          display: inline-block;
          border: $thick solid transparent;
          position: relative;
          min-width: 200px;
          font-size: 2em;
          cursor: pointer;
          letter-spacing: 0.07em;
          z-index: 2;
      
          .text {
            // padding: 0 0.3em;
            transform: translate3d(0,$pad,0);
            display: block;
            transition: transform 0.4s cubic-bezier(.2,0,0,1) 0.4s;
          }
      
          &:after {
            position: absolute;
            content: '';
            bottom: -$thick;
            left: $extra;
            right: $extra;
            height: $thick;
            background: white;
            // z-index: -1;
            transition: 
              transform 0.8s cubic-bezier(1,0,.37,1) 0.2s,
              right 0.2s cubic-bezier(.04,.48,0,1) 0.6s,
              left 0.4s cubic-bezier(.04,.48,0,1) 0.6s;
            transform-origin: left;
          }
      
        }
      
        .line {
          position: absolute;
          background: $color;
      
          &.-right,
          &.-left {
            width: $thick;
            bottom: -$thick;
            top: -$thick;
            transform: scale3d(1,0,1);
          }  
      
          &.-top,
          &.-bottom {
            height: $thick;
            left: -$thick;
            right: -$thick;
            transform: scale3d(0,1,1);
          }
      
          &.-right {
            right: -$thick;
            transition: transform 0.1s cubic-bezier(1,0,.65,1.01) 0.23s;
            transform-origin: top;
          }
      
          &.-top {
            top: -$thick;
            transition: transform 0.08s linear 0.43s;
            transform-origin: left;
          }
      
          &.-left {
            left: -$thick;
            transition: transform 0.08s linear 0.51s;
            transform-origin: bottom;
          }
      
          &.-bottom {
            bottom: -$thick;
            transition: transform 0.3s cubic-bezier(1,0,.65,1.01);
            transform-origin: right;
          }  
        }
      
        a:hover,
        a:active {
      
          .text {
            transform: translate3d(0,0,0);
            transition: transform 0.6s cubic-bezier(.2,0,0,1) 0.4s;
          }
      
          &:after {
            transform: scale3d(0,1,1);
            right: -$thick;
            left: -$thick;
            transform-origin: right;
            transition: 
              transform 0.2s cubic-bezier(1,0,.65,1.01) 0.17s,
              right 0.2s cubic-bezier(1,0,.65,1.01),
              left 0s 0.3s;
          }
      
          .line {
            transform: scale3d(1,1,1);
      
            &.-right {
              transition: transform 0.1s cubic-bezier(1,0,.65,1.01) 0.2s;
              transform-origin: bottom;
            }
      
            &.-top {
              transition: transform 0.08s linear 0.4s;
              transform-origin: right;
            }
      
            &.-left {
              transition: transform 0.08s linear 0.48s;
              transform-origin: top;
            }
      
            &.-bottom {
              transition: transform 0.5s cubic-bezier(0,.53,.29,1) 0.56s;
              transform-origin: left;
            }
          }
        }
      }
    }

    .panel-general {
      position: relative;
      width: 100vw;
      min-width: 900px;
      height: 100%;
    }

    .panel-wind {
      position: absolute;
      top: 0;
      left: 0;
      width: 182vw;
      height: 100%;
      background-attachment: fixed;
    }

    .panel-grid-cont {
      display: inline-grid;
      padding:3%;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: repeat(3, 1fr);
      gap: 10px 50px;
      width: 100%;
      height: 100%;
      place-items: center;
      justify-content: space-between;

      .float-box {
          position:relative;
          width: 100%;
          height: 80%;
          max-height: 700px;
          min-height: 400px;
          display: flex;
      }

      .float-left {
        grid-column-start:  1;
        grid-column-end: span 3;
        grid-row-start: 2;
        grid-row-end: 4;
      }

      .float-right {
          grid-column-start: 4;
          grid-column-end:  6;
          grid-row-start: 1;
          grid-row-end: 3;
      }

      .hover::after {
        transition: .5s;
        content: '';
        position: absolute;
        border-radius: 10px;
      }

      .hover.up::after {
        width: 100%;
        height: 0px;
        bottom: 0;
      }

      .hover.right::after {
        right: 0;
        width: 0px;
        height: 100%;
      }

      .hover.left::after {
        left: 0;
        width: 0px;
        height: 100%;
      }

      .hover:hover::after {
        transition: .7s ease-in-out, background-color 0s ease-in;
        background-color: white;
      }

      .hover.up:hover::after {
        height: 100%;
      }

      .hover.left:hover::after {
        width: 100%;
      }

      .hover.right:hover::after {
        width: 100%;
      }
      /*
      .float::after {
          transition: .5s;
          content: '';
          position: absolute;
          right:0;
          bottom: 0;
          width: 100%;
          height: 0px;
      }
      
      .float:hover::after {
          transition: .7s ease-in-out, background-color 0s ease-in;
          height: 100%;
          background-color: white;
          
      }*/
    }

    .flyer-panel-1 {
      position: absolute;
      bottom: -6%;
      left:20%;
      width: 700px;
      height: 700px;
      z-index: 0;

      img {
        max-width: 700px;
        max-height: 700px;
        
      }
    }  

  }
}

.transparent-background {
  background-color: transparent;
}

.offwhite {
  background-color: rgb(240,240,240);
}

.darkWhite {
  background-color: rgb(210,210,210);
}

.antique-white-background {
  background-color: antiquewhite;
}

.lightgrey {
  background-color: rgba(210,210,210, .4);
}

.lightGreenBlue {
  background-color: rgb(177,220,206);
}
.red {
  background-color: red;
}

.blue {
  background-color: blue;
}

.flex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.w40 {
  position: relative;
  width: 40%;
  height: 100%;
}

.w60 {
  position: relative;
  width: 60%;
  height: 100%;
}

.w80 {
  position: relative;
  width: 80%;
  height: 100%;
}

.w100 {
  position: relative;
  width: 100%;
  height: 100%;
}

.grid-col-start-1 {
  grid-column-start: 1;
}

.grid-col-start-2 {
  grid-column-start: 2;
}

.grid-col-start-3 {
  grid-column-start: 3;
}

.grid-col-start-4 {
  grid-column-start: 4;
}

.grid-col-span-2 {
  grid-column-end: span 2;
}

.grid-col-span-3 {
  grid-column-end: span 3;
}

.grid-row-start-1 {
  grid-row-start: 1;
}

.grid-row-start-2 {
  grid-row-start:2;
}

.grid-row-start-3 {
  grid-row-start: 3;
}

.grid-row-span-1 {
  grid-row-end: span 1;
}

.grid-row-span-2 {
  grid-row-end: span 2;
}

.grid-row-span-3 {
  grid-row-end: span 3;
}

.padding-10 {
  padding: 10%;
}

.padding-1 {
  padding: 1%;
}

.padding-2 {
  padding: 2%;
}

.padding-auto {
  padding: auto;
}

.border-radius-25 {
  border-radius: 25px;
}

.border-radius-10 {
  border-radius: 10px;
}

.box-shadow-small-black {
  box-shadow:  0px 0px 10px 5px rgba(210,210,210, .4);
}

.box-shadow-medium-black {
  box-shadow: 0px 0px 20px 0px black;
}

.max-width-100 {
  max-width: calc(100vw - 2%);
}

.width-100 {
  width: 100%;
}

.width-80 {
  width: 80%;
}

.width-40 {
  width: 40%;
}

.height-50 {
  height: 50%;
}

.height-80 {
  height: 80%;
}

.height-100 {
  height: 100%;
}

.min-height-75px {
  min-height: 75px;
}

.font-size-3vmin {
  font-size: 3vmin;
}

.font-color-white {
  color: white;
}

.font-color-red {
  color: red;
}

.justify-self-center {
  justify-self: center;
}

.align-self-center {
  align-self: center;
}

.justify-content-center {
  justify-content: center;
}

.align-content-center {
  align-items: center;
}

.align-content-end {
  align-content: flex-end;
}

.align-content-begin {
  align-items: flex-start;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.flex-wrap {
  flex-wrap: wrap;
}

.collapsible-cont {
  display: block;
  width: 98%;
  margin: 1%;
  background-color: antiquewhite;
  border-radius: 10px;
  cursor: pointer;

    input {
      position: absolute;
      opacity: 0;
      &:focus ~ .collapseItem {
        border-color: grey;
      }
  }

  .show-cont {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  
  .collapseItem {
    cursor: pointer;
    font-size: 3.2vmin;
    padding-right: 25px;
  }

  .arrowDown {
    float: right;
    display: inline-block;
    transform: rotate(90deg);
  }

  .collapsedItem {
    font-size: 0;
    margin: 0;
    opacity: 0;
    padding: 0;

    transition: 
    opacity .25s,
    margin .5s 0.25s,
    font-size .5s 0.25s,
    padding .5s .25s;
  }

  input:checked ~ .show-cont .arrowDown {
    transform: rotate(270deg);
  }

  input:checked ~ .collapsedItem {
    width: 90%;
    font-size: max(14px, 1.8vmin);
    opacity: 1;
    height: auto;
    padding: 25px 10px;

    transition: 
    margin .25s,
    padding .25s,
    font-size .25s,
    opacity .5s .25s;
  }

  .arrowDown {
    transition: transform .25s ease-out;
  }
}



.text-decoration-none {
  text-decoration: none;
}

.list-style-none {
  list-style-type: none;
}


.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}


.header-1-min {
  font-size: max(16px, 3.5vmin);
}

.header-2-min {
  font-size: max(14px, 2.5vmin);
}

.header-3-min {
  font-size: max(13px, 2.3vmin);
}

.header-4-min {
  font-size: max(12px, 2vmin);
}

.header-5-min {
  font-size: max(10px, 1.5vmin);
}

.p-min {
  font-size: max(10px, 1.5vmin);
}

.li-min {
  font-size: max(14px, 1.8vmin);
}




@media all and (max-width: 1700px) {
  .header-1-min {
    font-size: max(28px, 3.2vmin);
  }
  .header-2-min {
    font-size: max(24px, 2.2vmin);
  }
  .header-3-min {
    font-size: max(22px, 2.1vmin);
  }
  .header-4-min {
    font-size: max(20px, 1.8vmin);
  }
  .header-5-min {
    font-size: max(18px, 1.5vmin);
  }
  .p-min {
    font-size: max(16px, 1.4vmin);
  }
  .collapseItem {
    font-size: 3vmin;
  }
}

@media all and (max-width: 1500px) {

}

@media all and (max-width: 1300px) {
  .collapseItem {
    font-size: max(14px, 2.5vmin);
  }

  
}

@media all and (max-width: 1000px) {
  .scroll-container {

    .panels-container {

      .panels {
        .panel-title {

          .title-section {
            width: 80%;

          }


        }

        .panel-general {


          .float-box {
 

            img {

            }
          }
        }

        .panel-grid-cont {

          .float-box {
            flex-direction: column;
            justify-content: space-around;
            align-items: center;

            .flex {
              width: 100%;
              height: auto;
              overflow: hidden;
            }
          }

        }

      }

    }
  }
}

@media all and (max-width: 700px) {
  .scroll-container {

    .panels-container {

      .panels {


        .panel-title {

          .title-section {
            width: 80%;

          }


        }

        .panel-general {

          .float-box {
 

            img {

            }
          }
        }

        .flex-row-mobile {
          
        }

        .flex-col-mobile {
          
        }

        .price-styling {
          flex-direction: column;
          padding: 1%;
          height: auto;

          .price-level-styling {
            width: 100%;
            margin: 1%;
          }
        }

      }

    }
  }

  .header-1-min {
    font-size: max(18px, 2.0vmin);
  }
  .header-2-min {
    font-size: max(18px, 1.6vmin);
  }
  .header-3-min {
    font-size: max(16px, 1.6vmin);
  }
  .header-4-min {
    font-size: max(16px, 1.3vmin);
  }
  .header-5-min {
    font-size: max(12px, 1.0vmin);
  }
  .p-min {
    font-size: max(12px, 1.0vmin);
  }
  .collapseItem {
    font-size: 3vmin;
  }
}

@media all and (max-width: 500px) {

}