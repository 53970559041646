$lightGray:rgb(50, 56, 67);
$fontColor:hsl(0, 0%, 100%);
$accentColor:hsl(180, 100%, 50%);
$newAccent: #9DC7C8;


::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #939393;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(74, 74, 74);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.App {
  height: auto;
  width: 100%;
  min-width: 200px;
  background-color: black;
  position: relative;
}

.App-home-icon {
  position: fixed;
  width: 50px;
  height: 5%;
  padding: 10px 0 0 1%;
  z-index: 10;

  .App-home-icon-styling {
    position: relative;
    max-width: 50px;
    max-height: 50px;
    z-index: 10;
  }
}

.App-top-content {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: transparent;


    .App-content-intro {
      position: relative;
      width: 95%;
      height: 95%;
      left: 0%;
      top:5%;
      padding-right: 65%;
      overflow:hidden;

      .eva-testing {
        padding: 32px;

        align-self: baseline;
        font-family: 'Bebas Neue', cursive;
        font-weight: bolder;
        color: white;
        text-shadow: 0 0 2px $lightGray, 0 0 1.5px #854535, 0 0 1.5px #5c150c;
        display: flex;
        flex-direction: column;
        height: 50%;
  
        h1 {
          font-size: min(600%, calc(22px + 5vmin));
        }

        h2 {
          font-size: min(400%, calc(22px + 3vmin));
        }

        .eva-testing-header {
          transform: scale(1, 1.5);
          line-height: 1.2em;
          letter-spacing: .05em;
        }

        p {
          font-size: min(150%, calc(12px + 1.1vmin));
        }

        .split-content {

          padding-top: 20px;
          padding-bottom: 20px;
        }
        .eva-testing-p {
          line-height: 1.2em;
          letter-spacing: .05em;
        }
      }

      .App-content-intro-text {
        max-width: 900px;
      }

      .App-content-intro-intro {
        color: rgb(155,155,155);
        font-size: calc(10px + 1.0vmin);
      }

      h3 {
        width: 100%;
        color: $accentColor;
        margin: 0;
        font-size: calc(12px + 3.5vmin);
        line-height: 110%;
      }
    }
    
}

.App-top-content-transition-showcase {
  position: relative;
  height: 100vh;
  width: 100%;
  background: rgb(50,56,67);
  background: linear-gradient(180deg, rgba(50,56,67,1) 0%, rgba(50,56,67,1) 5%, rgba(0,0,0,1) 100%);
  display: grid;
  grid-template-columns: repeat(5,1fr);
  grid-template-rows: repeat(7,1fr);
  padding: 3%;
  font-family: 'Bebas Neue', cursive;
  font-weight: bolder;
  color: white;

  h2 {
    font-size: max(18px, 4vmin);
    letter-spacing: 0.02em;
    padding: 5% 0%;
  }

  p {
    font-size: max(16px, 2.2vmin);
    letter-spacing: 0.04em;
  }

  .about-flex {
    padding: 5%;

  }

  .about-likes {
    transition: .5s;
    position: relative;
    grid-column-start: 1;
    grid-column-end: span 3;
    grid-row-start: 1;
    grid-row-end: span 3;
    min-height: 200px;
    min-width: 100px;    
  }

  .about-content {
    transition: .5s;
    position: relative;
    grid-column-start: 1;
    grid-column-end: span 3;
    grid-row-start: 5;
    grid-row-end: span 3;
    min-height: 400px;
    min-width: 200px;

  }

  .about-experience {
    transition: .5s;
    position: relative;
    grid-column-start: 4;
    grid-column-end: span 2;
    grid-row-start: 2;
    grid-row-end: span 5;
    margin-left: 5%;
    min-height: 500px;
    min-width: 200px;

    .work-list {
      padding-left: 20px;
      text-decoration: none;
      list-style: none;
      font-size: max(10px, 1.7vmin)
    }
  }

  .about-hover::after {
    transition: .5s;
    content: '';
    position: absolute;
    right:0;
    top: 0;
    width: 0px;
    height: 100%;
  }

  .about-hover:hover::after {
    transition: 1s ease-in-out, background-color 0s ease-in;
    width: 100%;
    background-color: white;
    
  }
}

.App-top-content-showcase-transition {
  position: relative;
  height: 100vh;
  width: 100%;
  background: rgb(50,56,67);
  background: linear-gradient(0deg, rgba(50,56,67,1) 0%, rgba(50,56,67,1) 5%, rgba(0,0,0,1) 100%);


  .App-contact-top-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    position: relative;
    width: 100%;
    height: 30%;
    color: white;
    font-family: 'Bebas Neue', cursive;
    font-weight: bolder;
    font-size: 3.5vmin;
    padding: 0 60% 0 30px;
  }

  .contact-link-horizonal-bar-wrapper {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    flex-direction: column;
    max-width: 100%;
    padding: 0;
    min-height: 55%;
    color: white;
    font-family: 'Bebas Neue', cursive;
    font-weight: bolder;
    font-size: 3vmin;

    h3{
      min-height: 200px;
      display: flex;
      align-items: flex-end;
      padding-bottom: 30px;
      align-self: center;
    }

    .contact-link-horizontal-bar {
      position: relative;
      min-height: 100%;
      width: auto;

      display: flex;
      flex-direction: column;
      justify-content:center;
      align-items: center;

      a{
        text-decoration: none;
        color: rgb(220, 220, 220);
        transition: .5s ease-in-out;
      }

      a::after {
        content: '';
        width: 0px;
        height: 2px;
        background: white;
        display: none;
        padding: 0;
        margin: 0;
      }

      a:hover{
        color: white;
        transition: .5s ease-in-out;;

      }

      a:hover::after {
        display: block;
        width: 100%;
      }
    }
  }

  .home-section-title {
    transition: .5s ease-in-out;
    position: relative;
    font-size: 25vmin;
    color: white;
    position: relative;
    text-align: center;
    width: 100%;
    height: 25%;
    padding: 0 20% 0 30px;
    z-index: 5;
    font-family: 'Bebas Neue', cursive;
    font-weight: bolder;
    overflow-y: hidden;


    .home-stretch-text-wrapper {
      width: 100%;
      height: 100%;
      text-align: center;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: flex-end;
      line-height: 100%;
      cursor: pointer;
    }

  }

  .home-section-title:hover {
    transition: .5s ease-in-out;
    padding: 0 5% 0 5%;
    color:#9DC7C8;
  }

}

@media all and (max-width: 1100px) {
  .App-top-content {

    .App-content-intro {
      padding-right: 25%;

    }
  }

  .App-top-content-transition-showcase {

    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6,1fr);


    h2 {
      font-size: max(18px, 3vmin);
      letter-spacing: 0.02em;
      padding: 5% 0%;
    }
  
    p {
      font-size: max(16px, 1.7vmin);
      letter-spacing: 0.04em;
    }
  
    .about-flex {
      padding: 1%;
  
    }
  

    .about-likes {
      position: relative;
      grid-column-start: 1;
      grid-column-end: span 4;
      grid-row-start: 1;
      grid-row-end: span 2;
      min-height: 100px;
      min-width: none;

    }

    .about-content {
      position: relative;
      grid-column-start: 2;
      grid-column-end: span 4;
      grid-row-start: 3;
      grid-row-end: span 2;
      min-height: 100px;
      min-width: none;

    }

    .about-experience {
      position: relative;
      grid-column-start: 3;
      grid-column-end: span 4;
      grid-row-start: 5;
      grid-row-end: span 2;
      margin: 0;
      min-height: 100px;
      min-width: none;
    }
  }
}

@media screen and (max-width: 600px) {
  .App-top-content {

    .App-content-intro {
      padding-right: 15%;

    }
  }

  .App-top-content-transition-showcase {

    h2 {
      font-size: max(18px, 2.4vmin);
      letter-spacing: 0.02em;
      padding: 5% 0%;
    }
  
    p {
      font-size: max(14px, 1.4vmin);
      letter-spacing: 0.04em;
    }

    .about-likes {
      position: relative;
      grid-column-start: 1;
      grid-column-end: span 5;
    }

    .about-content {
      position: relative;
      grid-column-start: 1;
      grid-column-end: span 5;
    }

    .about-experience {
      position: relative;
      grid-column-start: 1;
      grid-column-end: span 5;
    }
  }
}

@media screen and (max-width: 300px) {
  .App-content-aboutme h3 {
    color: $accentColor;
    margin: 0;
    font-size: calc(20px);
    left: 0;
  }
}

.navActive {
  opacity: 0;
}

