
.application-top-section {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;


    .application-top-navbar {
        position: relative;
        height: auto;
        width: 100%;
        
    }

    .application-card-panel {
        position: relative;
        width: 100%;
        height: 100%;
        top: 80px;
        margin-bottom: 5%;
    }
}

.graphics-top-section {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;


    .graphics-top-navbar {
        position: relative;
        height: auto;
        width: 100%;
    }

    .graphics-card-panel {
        position: relative;
        width: 100%;
        height: 100%;
        top: 80px;
        margin-bottom: 5%;
    }
}

.otherwork-top-section {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;

    .otherwork-top-navbar {
        position: relative;
        height: auto;
        width: 100%;
        
    }

    .otherwork-card-panel {
        position: relative;
        width: 100%;
        height: 100%;
        top: 80px;
        margin-bottom: 5%;
    }
}

.section-title {
    position: relative;
    font-size: 12vmin;
    color: white;
    position: relative;
    width: 100%;
    height: 30%;
    padding-right: 40%;
    padding-left: calc(2% + 50px);
    left: 0;
    top: 30px;
    z-index: 5;
    font-family: 'Bebas Neue', cursive;
    font-weight: bolder;


    .stretch-text-wrapper {
      width: 100%;
      height: 100%;
      text-align: center;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      line-height: 100%;
    }
  }

  @media all and (max-width: 1500px) { .section-title { font-size: 9vmin;} }
  @media all and (max-width: 1200px) { .section-title { font-size: 8vmin;} }
  @media all and (max-width: 800px) { .section-title { font-size: 7vmin;} }
  @media all and (max-width: 500px) { .section-title { font-size: 6vmin;} }
