$lightGray:hsl(219, 15%, 23%);
$fontColor:hsl(0, 0%, 100%);
$accentColor:hsl(180, 100%, 50%);

.App {
  height: auto;
  width: 100%;
  min-width: 200px;
  background-color: $lightGray;
  position: relative;
}


.App-top-content {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: transparent;


    .App-content-intro {
      position: relative;
      width: 55%;
      height: auto;
      left: 40%;
      top:30%;
      padding-right: 5%;
      overflow:hidden;

      .App-content-intro-intro {
        color: rgb(155,155,155);
        font-size: calc(10px + 1.0vmin);
      }

      h3 {
        width: 100%;
        color: $accentColor;
        margin: 0;
        font-size: calc(12px + 3.5vmin);
        line-height: 110%;
      }
    }
    
}


.App-about-me {
  position: relative;
  width: 100%;
  height: 100vh;
  top: 100%;
  right: 0;
  background-color: $lightGray;
}

.App-bottom-footer {
  width: 100%;
  height: 100vh;
}

@media screen and (max-width: 300px) {
  .App-content-aboutme h3 {
    color: $accentColor;
    margin: 0;
    font-size: calc(20px);
    left: 0;
  }
}

.navActive {
  opacity: 0;
}

